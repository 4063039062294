import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/system";

import LogoTypography from "../shared/LogoTypography";
import ATMSLogoTypography from "../shared/ATMSLogoTypography";

const TopBar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#EFF1F2",
          height: 64,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "baseline",
            flexDirection: "row",
          }}
        >
          <LogoTypography
            style={{
              fontSize: "1.5rem",
              marginLeft: 15,
              marginRight: 5,
              textShadow: "none",
            }}
          />
          <ATMSLogoTypography
            onClick={() => {
              window.open("http://www.thefreetms.com", "_blank");
            }}
            style={{
              fontSize: ".9rem",
              color: "black",
              textShadow: "none",
              cursor: "pointer",
            }}
            pretext="powered by "
          />
          <InfoIcon
            style={{ marginLeft: 5, color: "black", fontSize: ".7rem" }}
          />
        </div>
      </AppBar>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        slotProps={{
          paper: { style: { padding: 10, maxWidth: 300 } },
        }}
      >
        <Typography>
          Get more loads from this customer and others by using{" "}
          <ATMSLogoTypography
            component="span"
            variant="span"
            style={{ color: "black", textShadow: "none" }}
          />
          . It’s totally free and we guarantee you more profits and lower costs
          when you use{" "}
          <ATMSLogoTypography
            component="span"
            variant="span"
            style={{ color: "black", textShadow: "none" }}
          />{" "}
          to manage your logistics business. Click{" "}
          <a rel="noreferrer" target="_blank" href="http://www.thefreetms.com">
            here
          </a>
          .
          <IconButton
            style={{ float: "right" }}
            onClick={handlePopoverClose}
            onMouseUp={handlePopoverClose}
            onTouchEnd={handlePopoverClose}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </Popover>
    </div>
  );
};

TopBar.propTypes = {};

export default TopBar;
