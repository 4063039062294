import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import ViewPDF from "./ViewPDF";
import TopBar from "./layout/TopBar";
import BottomBar from "./layout/BottomBar";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NavigationIcon from "@mui/icons-material/Navigation";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import SignatureCanvas from "react-signature-canvas";
import { Checkmark } from "react-checkmark";
import { CONSTANTS } from "./constants";
import Typography from "@mui/material/Typography";

const _steps = [
  {
    title: "Do you agree to sign this document?",
    optional: false,
    yesText: "Yes, I agree to sign this document",
    noText: null,
    cancelText: "Go Back to Document",
  },
  {
    stepId: "name",
    title: "Enter your full name below.",
    optional: false,
    yesText: "Continue",
    noText: null,
    cancelText: "Go Back",
  },
  {
    stepId: "additionalQuestions",
    title: "Add additional information (Optional)",
    optional: false,
    yesText: "Continue",
    noText: null,
    cancelText: "Go Back",
  },
  {
    stepId: "sigType",
    title: "Do you want to use the signature below, or draw your own?",
    optional: false,
    yesText: "Use the provided signature",
    noText: "Draw my own signature",
    noColor: "info",
    cancelText: "Go Back",
  },

  {
    stepId: "finalize",
    title: "Finalize your signature.",
    optional: false,
    yesText: "Finish Signing and Send Copy",
    noText: null,
    cancelText: "Go Back",
  },
  {
    stepId: "success",
    title: "Signing Complete!",
    optional: false,
    yesText: null,
    noText: null,
  },
];

const DelayedRedirect = ({ seconds, to }) => {
  const [counter, setCounter] = useState(seconds);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      window.location.href = to;
    }
    return () => clearInterval(timer);
  }, [counter, to]);

  return (
    <Typography variant="h6" component="div">
      Redirecting in {counter} seconds...
    </Typography>
  );
};

function trimCanvas(c) {
  var ctx = c.getContext("2d"),
    copy = document.createElement("canvas").getContext("2d"),
    pixels = ctx.getImageData(0, 0, c.width, c.height),
    l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null,
    },
    x,
    y;

  for (i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % c.width;
      y = ~~(i / 4 / c.width);

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }

  const trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left,
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

  copy.canvas.width = trimWidth;
  copy.canvas.height = trimHeight;
  copy.putImageData(trimmed, 0, 0);

  // open new window with trimmed image:
  return copy.canvas;
}

const Signature = ({ name, canvasRef }) => {
  useEffect(() => {
    const drawText = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      // Set font properties
      context.font = `40px ${CONSTANTS.signatureFont}`;
      context.textAlign = "center";
      context.fillStyle = "blue";

      // Measure the text width
      const metrics = context.measureText(name);
      const padding = 10; // Add optional padding

      // Adjust canvas width and height to fit the text
      canvas.width = metrics.width + padding * 2;
      canvas.height = 65;

      // Clear the canvas before re-drawing.
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Re-apply font properties after clearing the canvas
      context.font = `40px ${CONSTANTS.signatureFont}`;
      context.textAlign = "center";
      context.fillStyle = "blue";

      // Draw the text
      context.fillText(name, canvas.width / 2, canvas.height / 2);
    };

    if (document.fonts.check(`40px ${CONSTANTS.signatureFont}`)) {
      drawText();
    } else {
      document.fonts.ready.then(drawText);
    }
  }, [name, canvasRef]);

  return <canvas ref={canvasRef} />; // No need to set width and height here, it's set in drawText function
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Sign() {
  const [redirectIsSigned, setRedirectIsSigned] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [sigId, setSigId] = useState(null);
  const [showCancel, setShowCancel] = useState(false);

  const [step, setStep] = React.useState(0);

  const [canvasHasSig, setCanvasHasSig] = React.useState(false);
  const [name, setName] = React.useState("");
  const [docUrl, setDocUrl] = React.useState("");
  const [useOwnSig, setUseOwnSig] = React.useState(false);
  const [isSigned, setIsSigned] = React.useState(false);
  const [additionalAnswers, setAdditionalAnswers] = React.useState({});
  const [additionalQuestions, setAdditionalQuestions] = React.useState([]);
  const sigRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(false);

  const steps = _steps.filter((step) => {
    if (step.stepId === "additionalQuestions") {
      return additionalQuestions && additionalQuestions.length > 0;
    }

    return true;
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    var id = params.get("id");
    console.log("useEffect ~ id:", id);
    setSigId(id);

    axios
      .post(
        "https://iii25netvp4vcoohpnvziadhte0wtktp.lambda-url.us-east-1.on.aws",
        { sigId: id }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.sigUrl) {
          setDocUrl(response.data.sigUrl);
        }
        setIsSigned(response.data.isSigned);

        if (response.data.isSigned) {
          setRedirectIsSigned(true);
        }

        if (
          response.data.additionalQuestions &&
          response.data.additionalQuestions.length > 0
        ) {
          setAdditionalQuestions(response.data.additionalQuestions);
        }

        if (
          response.data.message &&
          response.data.message === "Signature request has been cancelled."
        ) {
          setShowCancel(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoading(false);
      });
  }, []); // Empty array means this effect runs once when the component mounts

  const nextStep = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      handleClose();
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      handleClose();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSign = async () => {
    nextStep();
    console.log("Sign");
    let sigImage;
    if (useOwnSig) {
      sigImage = sigRef.current.getTrimmedCanvas().toDataURL("image/png");
    } else {
      console.log("Using provided signature");
      const trimmedCanvas = trimCanvas(canvasRef.current);
      sigImage = trimmedCanvas.toDataURL("image/png");
    }
    console.log("handleSign ~ sigImage:", sigImage);

    try {
      const response = await axios.post(CONSTANTS.completeSigningUrl, {
        sigId,
        sigImage,
        sigName: name,
        additionalAnswers,
      });

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsSigned(true);
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setName("");
    setStep(0);
    setOpen(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <div>
          <CircularProgress size={100} />
        </div>
        <Typography variant="h6" component="div" style={{ marginTop: "20px" }}>
          Loading Document...
        </Typography>
      </div>
    );
  }

  if (!sigId || showCancel || redirectIsSigned) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" component="div" style={{ marginTop: "20px" }}>
          {redirectIsSigned
            ? "Document has been signed."
            : "Document Unavailable. Potential reasons: Signature request has been recalled or it has expired."}
        </Typography>
        <p style={{ textAlign: "center" }}>
          <DelayedRedirect seconds={10} to="https://www.thefreetms.com/" />
        </p>
      </div>
    );
  }

  return (
    <Box sx={{ pb: 7 }}>
      <TopBar />
      <span
        style={{
          fontSize: 1,
          color: "gray",
          fontFamily: CONSTANTS.signatureFont,
        }}
      >
        {" "}
      </span>
      {/* <Paper
        sx={{ position: "fixed", top: 0, left: 0, right: 0, height: 64, borderRadius: 0 }}
        elevation={3}
      >
        urmom
      </Paper> */}
      <CssBaseline />
      <div style={{ height: 64 }} />
      <ViewPDF docUrl={docUrl} />
      {redirectIsSigned ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: 64,
            borderRadius: 0,
            backgroundColor: "green",
            color: "white",
            textAlign: "center",
            padding: "10px 0",
          }}
        >
          Document has been signed.
          <DelayedRedirect seconds={10} to="https://www.thefreetms.com/" />
        </div>
      ) : null}
      {isSigned ? null : (
        <Button
          aria-label="begin signing"
          size="large"
          variant="contained"
          color="success"
          onClick={handleClickOpen}
          sx={{
            display: { xs: "none", md: "block" },
            position: "fixed",
            top: 120,
            right: "calc(50% - 140px)",
          }}
        >
          Click here to Begin Signing
        </Button>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div style={{ textAlign: "center" }}>
            {steps[step].stepId === "finalize" && useOwnSig
              ? "Draw your signature below, then press Finish Signing."
              : steps[step].stepId === "finalize" && !useOwnSig
              ? "Press Finish Signing button to submit your signature"
              : steps[step].title}
          </div>
        </DialogTitle>
        <DialogContent>
          {steps[step].stepId === "success" ? (
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <p>
                You will receive an email with a signed copy of the document.
              </p>
              <p>
                To download the original unsigned document,{" "}
                <a target="_blank" href={docUrl}>
                  click here.
                </a>
              </p>
              <Checkmark size="large" />
              <p style={{ textAlign: "center" }}>
                <DelayedRedirect
                  seconds={10}
                  to="https://www.thefreetms.com/"
                />
              </p>
            </div>
          ) : null}
          {steps[step].stepId === "additionalQuestions" ? (
            <div style={{ marginBottom: 15 }}>
              {additionalQuestions.map((question, index) => (
                <TextField
                  required={false}
                  key={index}
                  margin="dense"
                  id={question.id}
                  label={question.label}
                  type="text"
                  fullWidth
                  value={additionalAnswers[question.id] || ""}
                  onChange={(e) => {
                    setAdditionalAnswers({
                      ...additionalAnswers,
                      [question.id]: e.target.value,
                    });
                  }}
                />
              ))}
            </div>
          ) : null}

          {steps[step].stepId === "name" ? (
            <TextField
              required
              value={name}
              autoFocus
              margin="dense"
              id="name"
              label="Full Name"
              type="text"
              fullWidth
              style={{ marginBottom: 15 }}
              onChange={(e) => {
                setName(e.target.value);

                console.log(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevents the addition of a new line in the text field (not needed in a lot of cases)
                  nextStep();
                }
              }}
            />
          ) : null}
          {steps[step].stepId === "sigType" ? (
            <div>
              <Signature canvasRef={canvasRef} name={name} />
            </div>
          ) : null}
          {steps[step].stepId === "finalize" ? (
            !useOwnSig ? (
              <Signature canvasRef={canvasRef} name={name} />
            ) : (
              // Draw Signature
              <div>
                <SignatureCanvas
                  onEnd={() => {
                    setCanvasHasSig(true);
                  }}
                  ref={sigRef}
                  penColor="blue"
                  canvasProps={{
                    height: 200,
                    width: "calc(100%)",
                    className: "sigCanvas",
                    style: { border: "1px solid #000000" },
                  }}
                />
              </div>
            )
          ) : null}

          {!steps[step].yesText ? null : (
            <Button
              disabled={
                (steps[step].stepId === "name" && (!name || name.length < 1)) ||
                (steps[step].stepId === "finalize" &&
                  useOwnSig &&
                  !canvasHasSig)
              }
              fullWidth
              color="success"
              onClick={
                steps[step].stepId === "sigType"
                  ? () => {
                      setUseOwnSig(false);
                      nextStep();
                    }
                  : steps[step].stepId === "finalize"
                  ? handleSign
                  : nextStep
              }
              size="large"
              variant="contained"
            >
              {steps[step].yesText}
            </Button>
          )}

          {steps[step].stepId === "finalize" && useOwnSig ? (
            <Button
              style={{ marginTop: 10 }}
              fullWidth
              color="error"
              onClick={() => {
                setCanvasHasSig(false);
                sigRef.current.clear();
              }}
              size="large"
              variant="outlined"
            >
              Clear Signature
            </Button>
          ) : null}

          {!steps[step].noText ? null : (
            <Button
              style={{ marginTop: 10 }}
              color={steps[step].noColor || "error"}
              size="large"
              variant="outlined"
              fullWidth
              onClick={
                steps[step].stepId === "sigType"
                  ? () => {
                      setUseOwnSig(true);
                      nextStep();
                    }
                  : handleClose
              }
            >
              {steps[step].noText}
            </Button>
          )}
          {!steps[step].cancelText ? null : (
            <Button
              style={{ marginTop: 10 }}
              color="info"
              size="large"
              variant="outlined"
              fullWidth
              onClick={prevStep}
            >
              {steps[step].cancelText}
            </Button>
          )}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button color="success" onClick={nextStep}>Yes</Button>
        </DialogActions> */}
      </Dialog>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: 0,
          backgroundColor: "#EFF1F2",
          height: 64,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          cursor: "pointer",
        }}
        elevation={3}
      >
        {isSigned ? (
          <Button
            disabled
            size="large"
            variant="contained"
            color="success"
            onClick={handleClickOpen}
          >
            <Checkmark size="medium" />
            <span style={{ paddingLeft: 5 }}>Signing Complete</span>
          </Button>
        ) : (
          <Button
            size="large"
            variant="contained"
            color="success"
            onClick={handleClickOpen}
          >
            <EditIcon style={{ paddingRight: 5 }} />
            Begin Signing
          </Button>
        )}
      </Paper>
      {/* <BottomBar/> */}
    </Box>
  );
}

// Have you read the entire document?
// Do you agree to sign the document?
// Enter your full name
// Do you want to use the signature below, or draw your own?
// Draw my own / Use provided Signature
