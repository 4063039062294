import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ViewPDF from "./ViewPDF";
import SignatureCanvas from "react-signature-canvas";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

const defaultTheme = createTheme();

const styles = {
  appBar: {
    top: "auto",
    bottom: 0,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  content: {
    flex: 1,
  },
  footer: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    backgroundColor: "#3f51b5",
    color: "#fff",
    textAlign: "center",
    padding: "10px 0",
  },
};


export default function SignIn() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div styles={{ position: "relative", minHeight: "100vh" }}>
        <Container component="main">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <AppBar position="fixed">
              <Toolbar>
                <Typography variant="h6" noWrap component="div">
                  Header
                </Typography>
              </Toolbar>
            </AppBar>
            <Toolbar />
            <Box>{/* <ViewPDF /> */}</Box>
          </Box>
        </Container>
        <Box sx={styles.footer}>
          <Typography variant="body1">Sticky Footer</Typography>
        </Box>
      </div>
    </ThemeProvider>
  );
}

{
  /* <Box
sx={{
  marginTop: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}}
>
<Box
  component="form"
  onSubmit={handleSubmit}
  noValidate
  sx={{ mt: 1 }}
>
  <TextField
    margin="normal"
    required
    fullWidth
    id="email"
    label="Type your full name..."
    name="email"
    autoComplete="email"
    autoFocus
  />

  <FormControlLabel
    control={<Checkbox value="remember" color="primary" />}
    label="I agree to electronically sign this document."
  />
  <SignatureCanvas
    penColor="green"
    canvasProps={{
      height: 300,
      className: "sigCanvas",
      style: { border: "1px solid #000000" },
    }}
  />

  <Button
    type="submit"
    fullWidth
    variant="contained"
    sx={{ mt: 3, mb: 2 }}
  >
    Sign Document
  </Button>
</Box>
</Box> */
}
