import { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import useMediaQuery from '@mui/material/useMediaQuery';

function OpenPDFBanner({ docUrl }) {
  const openPDF = () => {
    window.open(docUrl, "_blank");
  };
  const matches = useMediaQuery('(max-width:600px)');

  if (!matches) {
      return null;
  }


  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "primary.main",
        color: "white",
        p: 1,
      }}
      onClick={openPDF}
      role="button"
      tabIndex={0}
    >
      Press here to open document in a new tab.
    </Box>
  );
}

function ViewPDF({ docUrl }) {
  return (
    <div>
      <OpenPDFBanner docUrl={docUrl} />
      <iframe
        src={docUrl}
        width="100%"
        height={`${window.innerHeight - 64}px`}
        style={{ border: "none" }}
        title="PDF Viewer"
      ></iframe>
    </div>
  );
}

export default ViewPDF;
